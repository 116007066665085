<template>
  <div class="body tw-py-10">
    <!-- <img
      src="@/assets/logos/blue.png

"
      alt="logo"
      style="height: 25px; width: 130px"
      class="tw-cursor-pointer"
      @click="$router.push({ name: 'HomePage' })"
    /> -->
    <div class="tw-flex tw-w-full tw-justify-center tw-py-10">
      <h6 class="sign-up-as">Sign Up As</h6>
    </div>
    <div
      class="
        tw-flex tw-w-full tw-justify-center
        lg:tw-flex-row
        tw-flex-col
        allcards
        tw-pb-5
      "
    >
      <v-card
          flat
          class="
          card
          tw-px-4 tw-pb-10
          pt-15
          tw-flex tw-flex-col tw-items-center
          lg:tw-mx-12
        "
      >
        <div class="avatar-div" style="border: 5px solid #3F00C5">
          <h6 class="avatar-text" style="color: #3F00C5">C</h6>
        </div>
        <h6 class="avatar-title tw-my-3" style="color: #3F00C5">
          For Commuters
        </h6>
        <h6 class="avatar-desc tw-my-3">
          For Users and Travellers who want to move from place to place using a transport service.
        </h6>
        <v-btn
            style="background-color: #3F00C5"
            text
            class="avatar-btn"
            @click="$router.push({ name: 'CustomerSignUp' })"
        >
          Sign Up
        </v-btn>
      </v-card>
      <div  class="vertical"></div>
      <v-card
        flat
        class="
          card
          tw-px-4 tw-pb-10
          pt-15
          tw-flex tw-flex-col tw-items-center
          lg:tw-mx-12
        "
      >
        <div class="avatar-div" style="border: 5px solid #004aad">
          <h6 class="avatar-text" style="color: #004aad">S</h6>
        </div>
        <h6 class="avatar-title tw-my-3" style="color: #004aad">
          Service Provider
        </h6>
        <h6 class="avatar-desc tw-my-3">
          For Companies who are in the business of transporting people
          from place to place or lease vehicles.
        </h6>
        <v-btn
          style="background-color: #004aad"
          text
          class="avatar-btn"
          @click="$router.push({ name: 'ChooseYourNiche' })"
        >
          Sign Up
        </v-btn>
      </v-card>
            <div class="vertical"></div>
      <div class="vertical"></div>
      <v-card
          flat
          class="
          card
          tw-px-4 tw-pb-10
          pt-15
          tw-flex tw-flex-col tw-items-center
          lg:tw-mx-12
        "
      >
        <div class="avatar-div" style="border: 5px solid #1e5770">
          <h6 class="avatar-text" style="color: #1e5770">M</h6>
        </div>
        <h6 class="avatar-title tw-my-3" style="color: #1e5770">
          Travel Agent
        </h6>
        <h6 class="avatar-desc tw-my-3">
          Any one who want to sell travel ticket and make extra income.
        </h6>
        <v-btn
            style="background-color: #1e5770"
            text
            class="avatar-btn"
            @click="$router.push({ name: 'IndividualAgentRegister' })"
        >
          Sign Up
        </v-btn>
      </v-card>
      <div  class="vertical"></div>
<!--      <v-card-->
<!--          flat-->
<!--          class="-->
<!--          card-->
<!--          tw-px-4 tw-pb-10-->
<!--          pt-15-->
<!--          tw-flex tw-flex-col tw-items-center-->
<!--          lg:tw-mx-12-->
<!--        "-->
<!--      >-->
<!--        <div class="avatar-div" style="border: 5px solid #3F00C5">-->
<!--          <h6 class="avatar-text" style="color: #3F00C5">IP</h6>-->
<!--        </div>-->
<!--        <h6 class="avatar-title tw-my-3" style="color: #3F00C5">-->
<!--          Insurance Provider-->
<!--        </h6>-->
<!--        <h6 class="avatar-desc tw-my-3">-->
<!--          Insurance companies that offer insurance services to travellers and service providers.-->
<!--        </h6>-->
<!--        <v-btn-->
<!--            style="background-color: #3F00C5"-->
<!--            text-->
<!--            class="avatar-btn"-->
<!--            @click="$router.push({ name: 'InsuranceProviderRegister' })"-->
<!--        >-->
<!--          Sign Up-->
<!--        </v-btn>-->
<!--      </v-card>-->
      <div style="display: none" class="vertical"></div>
   <v-card
        flat
         style="display: none"
        class="
          card
          tw-px-4 tw-pb-10
          pt-15
          tw-flex tw-flex-col tw-items-center
          lg:tw-mx-12
        "

      >
        <div class="avatar-div" style="border: 5px solid #023A86">
          <h6 class="avatar-text" style="color: #004aad">C</h6>
        </div>
        <h6 class="avatar-title tw-my-3" style="color: #004aad">
       Customer
        </h6>
        <h6 class="avatar-desc tw-my-3">
        For Travellers to make Seamless Travel bookings
        </h6>
        <v-btn
          style="background-color: #023A86"
          text
          class="avatar-btn"
          @click="$router.push({ name: 'CustomerSignUp' })"
        >
          Sign Up
        </v-btn>
      </v-card>
      <div class="vertical tw-hidden"></div>
      <v-card
        flat
        class="
          card
          tw-px-4 tw-pb-10
          pt-15
          tw-hidden tw-flex-col tw-items-center
          lg:tw-mx-12
        "
      >
        <div class="avatar-div" style="border: 5px solid #1d262d">
          <h6 class="avatar-text" style="color: #1d262d">F</h6>
        </div>
        <h6 class="avatar-title tw-my-3" style="color: #1d262d">
          Fleet Management
        </h6>
        <h6 class="avatar-desc tw-my-3">
          For Corporates and businesses who have and manage a large fleet.
        </h6>
        <v-btn

          style="background-color: #1d262d; color: white"
          text
          class="avatar-btn mb-3"
         @click="$router.push({name: 'FleetManagementRegister'})">
          Sign Up
        </v-btn>
      </v-card>
      <hr class="vertical tw-hidden" />

      <v-card
        flat
        class="
          card
          tw-px-4 tw-pb-10
          pt-15
          tw-hidden tw-flex-col tw-items-center
          lg:tw-mx-12
        "
      >
        <div class="avatar-div" style="border: 5px solid #1d262d">
          <h6 class="avatar-text" style="color: #1d262d">D</h6>
        </div>
        <h6 class="avatar-title tw-my-3" style="color: #1d262d">Developer</h6>
        <h6 class="avatar-desc tw-my-3">
          For Developers working with Transport or Fleet Management Companies
        </h6>
        <v-btn
          style="background-color: #1d262d"
          text
          class="avatar-btn"
          @click="$router.push({ name: 'DeveloperRegister' })"
        >
          Sign Up
        </v-btn>
      </v-card>

      <hr class="vertical tw-hidden"/>

      <v-card
          flat
          class="tw-hidden
          card
          tw-px-4 tw-pb-10
          pt-15
          tw-flex tw-flex-col tw-items-center
          lg:tw-mx-12
        "
      >
        <div class="avatar-div" style="border: 5px solid #1d262d">
          <h6 class="avatar-text" style="color: #1d262d">W</h6>
        </div>
        <h6 class="avatar-title tw-my-3" style="color: #1d262d">Waves</h6>
        <h6 class="avatar-desc tw-my-3">
          For Water Transport Operators who are in the business of transporting people
          from place to place.
        </h6>
        <v-btn
            style="background-color: #1d262d"
            text
            class="avatar-btn"
            @click="$router.push({ name: 'WaterTransportOperatorRegister' })"
        >
          Sign Up
        </v-btn>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegisterAs",
  components: {},
};
</script>

<style scoped lang="scss">
.body {
  background-image: url("../assets/plain.svg");
  background-size: cover;
  height: 100vh;
  flex-direction: column;
  width: 100%;
}
.allcards {
  @media (max-width: 1024px) {
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }
}
.sign-up-as {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 64px;
  color: var(--padiGrey);
}

.card {
  min-height: 440px;
  width: 350px;

  border: none;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 0.05) !important;

  @media screen and (max-width: 576px) {
    width: 80%;
  }
}
//.card:hover {
//  background-color: #ffffff;
//  border-radius: 10px;
//  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05) !important;
//  .avatar-btn,
//  .disappear {
//    display: flex;
//  }
//}

.avatar-div {
  display: flex;
  width: 110px;
  height: 102px;
  background: none;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}

.avatar-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 64px;
}
.vertical {
  border-left: 2px solid rgba(0, 0, 0, 0.19);
  height: 200px;
  margin-top: 5rem;
  @media (max-width: 1024px) {
    display: none;
  }
}
.avatar-title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin: 30px 0 15px;
}

.avatar-desc {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  margin: 0 auto;
  color: var(--padiGrey);
  text-align: center;
  height: 120px;
}
.disappear {
  display: flex;
}
.avatar-btn {
  width: 213px;
  height: 52px !important;
  box-shadow: 0 2px 10px rgba(130, 130, 130, 0.2);
  border-radius: 10px;
  text-transform: none;
  color: #ffffff !important;
  display: flex;
}
</style>